import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Masthead from '../components/masthead';
import Service from '../components/service-content';
import Testimony from '../components/testimonial';

export default ({ pageContext }) => (
	// Remove commas, and copyright symbol, from title and change spaces to dashes
	<Layout
		page={pageContext.title
			.replace(/,/g, '')
			.replace(/®/g, '')
			.replace(/\s/g, '-')}
	>
		<SEO title={pageContext.title} description={pageContext.description} />
		<Masthead title={pageContext.title} image={pageContext.mastImg} />
		<Service
			quote={pageContext.quote}
			content={pageContext.content}
			page={pageContext.page}
		/>
		<Testimony data={pageContext.testimony} />
	</Layout>
);
