import React from 'react';
import styled from 'styled-components';

const StyledTestimonial = styled.div`
	background-color: ${(p) => p.theme.color.orange};
	padding: 2.5rem ${(p) => p.theme.gutter} 3rem;
	color: #fff;
	&.home .text {
		max-width: 800px;
	}

	.quote-container {
		height: 3rem;
		margin-bottom: 1.5rem;
		.quotes {
			font-size: 10rem;
			line-height: 10rem;
			text-align: center;
			font-family: ${(p) => p.theme.fonts.light};
			font-weight: 700;
			color: #c6621c;
		}
	}

	.text {
		max-width: 950px;
		margin: 0 auto;
	}

	.author {
		margin-top: 2rem;
		margin-left: auto;
		width: 190px;
		width: fit-content;
		p {
			margin: 0;
			line-height: 1.2rem;
			font-family: ${(p) => p.theme.fonts.title};
		}
	}

	@media all and (min-width: 1600px) {
		.text {
			max-width: 1200px;
		}
		.author {
			width: 300px;
			width: fit-content;
			margin-top: 3rem;
			p {
				line-height: 1.7rem;
			}
		}
	}
	@media all and (max-width: 500px) {
		padding: 2.5rem ${(p) => p.theme.gutterMobile} 3rem;
	}
`;

export default ({ data, page }) => {
	return (
		<StyledTestimonial className={page}>
			<div className="quote-container">
				<p className="quotes">&ldquo;</p>
			</div>
			<p className="text">{data.content}</p>
			<div className="author">
				<p>{data.author}</p>
				<p>{data.location}</p>
			</div>
		</StyledTestimonial>
	);
};
