import React from 'react';
import styled from 'styled-components';
import mdToHtml from '../util/mdToHtml';

const StyledService = styled.div`
	.quote {
		background-color: ${(p) => p.theme.color.blue};
		padding: 4rem ${(p) => p.theme.gutter};
		text-align: center;
		font-size: 1.1rem;
		color: #fff;
		.text-container {
			width: 70%;
			margin: 0 auto;
			p {
				white-space: pre-wrap;
				&:nth-child(2) {
					padding-top: 1rem;
				}
			}
		}
	}

	.content-container {
		padding: 4rem ${(p) => p.theme.gutter};
		display: flex;
		flex-wrap: wrap;
		.full {
			width: 100%;
		}
		.left {
			width: 50%;
			padding-right: 1rem;
		}
		.right {
			width: 50%;
			padding-left: 1rem;
		}
		.pBottom {
			padding-bottom: 1.5rem;
		}
		.tallH3 {
			h3 {
				line-height: 2rem;
			}
		}
		.indentFix ul h3,
		.indentFix ul h3 + p {
			 {
				position: relative;
				left: -1.5rem;
			}
		}
		.keys .img-container {
			padding: 0;
			@media all and (max-width: 1599px) {
				max-width: 400px;
			}
		}

		h2 {
			padding-bottom: 2rem;
			margin: 1rem 0 0;
			color: ${(p) => p.theme.color.dark};
			font-weight: 700;
			position: relative;
			&::after {
				content: '';
				width: 50px;
				height: 3px;
				position: absolute;
				bottom: 0.8rem;
				left: 0;
				background-color: ${(p) => p.theme.color.orange};
			}
		}
		h3 {
			color: ${(p) => p.theme.color.blue};
			text-transform: uppercase;
			font-family: ${(p) => p.theme.fonts.title};
			font-size: 1.25rem;
			font-weight: 700;
		}
		h4 {
			font-size: 1.2rem;
			font-family: ${(p) => p.theme.fonts.title};
			font-weight: 700;
			margin-bottom: 0.5rem;
		}
		p {
			margin-bottom: 1rem;
		}
		li {
			margin-bottom: 0.5rem;
			margin-left: 1.5rem;
			position: relative;
			&::before {
				content: '';
				background-color: ${(p) => p.theme.color.orange};
				height: 7px;
				width: 7px;
				border-radius: 7px;
				position: absolute;
				left: -1rem;
				top: 8px;
			}
		}
		.img-container {
			width: 80%;
			padding: 0 1rem;
		}
	}

	&.home-auto {
		.right {
			max-width: 500px;
			margin: 0 auto;
			display: flex;
			align-items: center;
		}
		.content-container .img-container {
			margin: 0 auto 1rem;
		}
	}

	@media all and (min-width: 1200px) {
		.content-container,
		.quote {
			padding: 4rem ${(p) => p.theme.gutterWide};
		}
	}
	@media all and (min-width: 1600px) {
		.content-container,
		.quote {
			padding: 6rem ${(p) => p.theme.gutterXWide};
		}
		.content-container {
			h2 {
				font-size: 2rem;
				line-height: 2.5rem;
			}
			h3,
			h4 {
				font-size: 1.5rem;
				line-height: 2rem;
			}
			li {
				font-size: 1.25rem;
				line-height: 1.8rem;
			}
			p {
				margin-bottom: 2rem;
			}
		}
		&.home-auto {
			.left {
				width: 60%;
			}
			.right {
				width: 40%;
				max-width: 600px;
				margin: 0 auto 2rem;
			}
		}
	}
	@media all and (max-width: 878px) {
		.content-container {
			.left,
			.right {
				width: 100%;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
	@media all and (max-width: 768px) {
		.quote {
			.text-container {
				width: 100%;
			}
		}
	}
	@media all and (max-width: 500px) {
		.content-container,
		.quote {
			padding: 2rem ${(p) => p.theme.gutterMobile};
		}
		&.home-auto .content-container .img-container {
			width: 100%;
		}
	}
`;

export default ({ quote, content, page }) => {
	return (
		<StyledService className={page}>
			{quote && (
				<div className="quote">
					<div className="text-container">
						<div
							dangerouslySetInnerHTML={{
								__html: mdToHtml(quote),
							}}
						/>
					</div>
				</div>
			)}
			<div className="content-container">
				{content.map((x, i) => (
					<div
						className={x.className}
						key={i}
						dangerouslySetInnerHTML={{
							__html: mdToHtml(x.text),
						}}
					/>
				))}
			</div>
		</StyledService>
	);
};
